import React, { useEffect } from 'react'
import { showToast } from '@creditas-ui/react'
import { useTranslation } from 'react-i18next'

const Notifications = () => {
  const { t } = useTranslation()

  const RenderComponent = {
    loanSolicitationReceived: {
      description: t('notify.LoanSolicitation.description'),
      config: {
        duration: 3000,
      },
    },
    salaryAdvanceSolicitationReceived: {
      description:
        t('notify.SalaryAdvanceSolicitation.title') +
        '. ' +
        t('notify.SalaryAdvanceSolicitation.description'),
      config: {
        duration: 3000,
      },
    },
    pendingAcquittanceLoanBillet: {
      description: t('notify.PendingAcquittance.description'),
      config: {
        isCloseable: true,
        link: {
          text: t('notify.PendingAcquittance.action'),
          target: 'blank',
        },
      },
    },
  }

  useEffect(() => {
    document.addEventListener('notifications', handleComponent)
    return () => {
      document.removeEventListener('notifications', handleComponent)
    }
  }, [])

  function handleComponent(event: any) {
    const { id, data } = event?.detail

    const { description, config } = RenderComponent[id] || {}

    if (data?.redirectTo != null) {
      config.link = {
        text: t('notify.PendingAcquittance.action'),
        href: data.redirectTo,
        target: 'blank',
      }
    }

    if (description !== null && config !== null) {
      showToast(description, config)
    }
  }

  return <></>
}

export { Notifications }
