import React from 'react'

import { Notifications } from './Notifications'
import { TranslatedErrorBoundary } from '../../components'

import tokens from '@creditas/tokens'
import { ThemeProvider as LegacyThemeProvider } from '@creditas/stylitas'
import { ThemeProvider, standard } from '@creditas-ui/react'
import { Toaster } from '@creditas-ui/react'

class App extends React.Component {
  componentDidCatch() {}

  render() {
    return (
      <TranslatedErrorBoundary>
        <LegacyThemeProvider theme={tokens}>
          <ThemeProvider theme={standard}>
            <Notifications />
            <Toaster />
          </ThemeProvider>
        </LegacyThemeProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
